:root {
    --blue: rgb(16, 41, 76);
    --dark-blue: #0d1623;
    --light-blue2: #007aff;
    --light-blue: #44A2F4;
    --light-grey: #dddddd;
    --lighter-grey: #eeeeee;
    --grey: #777777;
    --mid-grey: #aaaaaa;
    --mid-grey2: #444444;
    --mid-grey3: #2A2A2B;
    --dark-grey: #212121;
    --white-20: rgba(255,255,255,0.2);
    --black-20: rgba(0, 0, 0, 0.2);
    --yellow: rgb(255, 200, 0);
    --gold: #DCC080;
    --red: #DC1228;
  }

body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    /* overflow: hidden; */
}

*::-webkit-scrollbar {
    background: rgba(0,0,0,0.25);
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: #212121;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,0.1);
    height: 50px;
    border-radius: 6px;
    outline: none
  }

.small {
    font-size: 14px;
}

/* body::-webkit-scrollbar {
    display: none;
} */


p {
    margin: 0 0 10px 0;
    padding: 0;
}

section {
    width: 100%;
}

.app {
    
    min-height: 100vh;
   
    background: #212121;
    color: white;
}

.app.light {
    color: #212121;
    background: white;
}

.page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
}

.main {
    padding: 50px 0 2% 0;
    height: calc(100% - 50px);
}

a,
a:visited {
    text-decoration: none;
    color: #fff;
}

h2 {
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 20px;
    font-weight: 400;
}

.hover {
    cursor: pointer;
}

.hide {
    display: none;
}

h1, h2, h3 {
    margin: 0;
    padding: 0;
}

h2 {
    font-size: 14px;
    font-weight: 500;
}

.icon path {
    fill: white;
}

.icon path#add_state_btn {
    fill: fuchsia;
}

.icon2 {
    width: 12px;
    height: 12px;
}

.iconField {
    display: flex;
    gap: 10px;
    align-items: center;

}

.iconField.on {
    color: var(--light-blue);
}

.iconField.on path{
    /* background-color: var(--light-blue2); */
    fill: var(--light-blue)
}

.iconField.rotate svg{
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
}

@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

.fillWhite path {
    fill: white;
}

/* Loader */

#loading, #errorPopup, .modalContainer {
    width: 100vw;
    height: 100vh;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: opacity 0.3s ease;
}

#loading img {
    width: 150px;
    left: 50px;
    position: relative;
    height: auto;
}

#loading.on {
    opacity: 1;
    height: 100vh;
}

#errorPopup {
    /* font-size: 42px; */
    flex-direction: column;
}


.errorRow span:last-child {
    border-left: 1px solid var(--white-20);
}

.errorRow.oneBtn span:last-child  {
    border-left: none;
}

.contextMenu {
    position: absolute;
    z-index: 3;
    
    padding: 0 0;
    box-shadow: 0 12px 20px 0px rgba(0, 0, 0, 0.3); 
    cursor: pointer;
    opacity: 0;
    z-index: -1;
    width: 150px;
    display: flex;
    flex-direction: column;
}

.break {
    border-top: 0.5px solid var(--white-20);
    background: var(--mid-grey3);
}

.contextBtn {
    border-right: 1px solid var(--mid-grey2);
    border-left: 1px solid var(--mid-grey2);
    padding: 8px 16px;
    transition: background 0.3s ease;
    background: var(--mid-grey3);
}

.contextBtn:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top: 1px solid var(--mid-grey2);
}

.contextBtn:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 1px solid var(--mid-grey2);
}

.contextBtn:hover {
    background: var(--light-blue2);
}


.modal {
    /* border: 1px solid var(--white-20); */
    border-radius: 10px;
    width: 30vw;
    min-height: 330px;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 80px;
    background: var(--mid-grey3);
    padding: 0 0 0 0;
    box-shadow: 0 12px 20px 0px rgba(0, 0, 0, 0.3); 
    font-size: 30px;
    text-align: center;
    align-items: center;  
}

.threeBtn .modal {
    grid-template-rows: 1fr 1fr;
}

.shareModal .modal{
    align-items: flex-start;
    justify-items: flex-end;
    grid-template-rows: 1fr;
    min-height: 300px;
}

.modal h3  {
    font-weight: 700;
    margin: 0 0 20px 0;
    font-size: 24px;
}

.modal p {
    line-height: 150%;
    margin-bottom: 0;
}

.modal .textContainer {
    padding: 0px 40px;

}

.modal .buttons {
    display: flex;
    gap: 10px;
}

.modal .buttons .btn1 {
    border: 1px solid var(--white-20)
}

.shareModal .textContainer .textInner {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.shareModal .textContainer h3 {
    margin: 20px 0;
    text-align: left;
}

.shareModal .textContainer {
    width: calc(100% - 80px);
    height: 100%;
    display: grid;
    text-align: left;
    align-items: flex-start;
    grid-template-columns: 1fr;
    grid-template-rows: 80px 1fr;

}

.closeBtn {
    position: absolute;
    padding: 20px;

}

.errorRow span {
    font-weight: 400;
    margin: 0 0 0 0;
    font-size: 20px;
    color: var(--light-blue)
}

.modal p, .dropDownSelection {
    font-size: 18px;
}

.pageDropDown {
    width: 200px;
}

.modalBtn {
    margin: 20px 0 0 0;
}

.modal .dropDownField {
    left: 50vw;
}

.modal .dropDownChoice {
    width: 200px;
}

.settingsPageDropDown .dropDownChoice {
    width: 180px;
} 

.modal .dropDown {
    left: 150px;
}

.settingsRow .pageDropDown {
    margin-top: 20px;
}

.errorRow {
    width: 100%;
    border-top: 1px solid var(--white-20);
    padding: 0px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
}

.errorRow.oneBtn {
    grid-template-columns: 1fr;
}

.threeBtn .errorRow{
    grid-template-columns: 1fr; 
}

.threeBtn .errorRow span {
    border-bottom: 1px solid var(--white-20);
}

.threeBtn .errorRow span:last-child {
    border-left: none;
    border-bottom: none;
}

.errorRow span.saveContinue {
    background: var(--light-blue2);
    color: white;
}

.errorRow span {
    /* line-height: 300%;
    padding: 20px; */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 80px;
    /* height: 80px; */
}


/* Navigation */

header {
    border-bottom: 1px solid var(--white-20);
    width: 100%;
    width: 96%;
    padding: 8px 2%;
    /* display: flex;
    justify-content: space-between; */
    align-items: center;
    height: 34px;
    position: fixed;
    top: 0;
    z-index: 10;
    background: #212121;
    display: grid;
    grid-template-columns: 15vw 70vw 15vw;
}

nav {
    
}

nav a {
    /* padding: 0 30px 0 0; */
}

/* General Layout */

.col {
    padding: 0 2%;
    width: 96%;
}

.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    
}

.gridItem {

}

.gridItem.locked {
    cursor: auto;
}

.gridThumb {
    aspect-ratio: 1.78/1;
    border-radius: 6px;
    border: 1px solid rgba(255,255,255,0.5);
    overflow: hidden;
    margin-bottom: 10px;
}


.gridThumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* 
.lock {
    position: absolute;
}

.lock .icon {
    position: relative;
    top: -40px;
    left: 10px;
} */

.gridHeading {
    display: flex;
    height: 18px;
    align-items: center;
    width: 100%;
    gap: 10px;
}


.newIcon circle {
    
    fill: var(--light-blue2);
}

.appGrid {
    /* display: grid;
    grid-template-columns: 0.3fr 1fr 0.3fr;
    gap: 30px; */
    min-height: calc(100vh - 100px);
  
}


.leftNav {
    border-right: 0.5px solid rgba(255,255,255,0.5);
    position: fixed;
    width: 14vw;
    height: 100%;
    /* padding-top: 2vw; */
    top: 50px;
    padding: 1.5% 2vw;
    left: 0;

}

.homeLeftNav.leftNav {
    width: 17%;
    padding: 1.5% 0;
}


.core {
    display: flex;
    flex-direction: column;
    left: 18%;
    width: 80%;
    position: relative;
    padding-top: 2vw;
    overflow-x: visible;

}


.twoCol {
    display: grid;
    grid-template-columns: 80% 20%;
    gap: 40px;
 
}

.ui {
    cursor: pointer;
}


.card {
    aspect-ratio: 16 / 9;
    width: 1920px;
    height: 1080px;
    transform: scale(50%) translate(-50%, -50%);
}


iframe.narrativePreview {
    float: left;
	height: 100%;
	width: 100%;
    border: none;
    border-radius: 20px;
    border: 0.5px solid var(--white-20);
    background: var(--mid-grey2);
    overflow: hidden;
    /* overflow-clip-margin: auto; */
}

.card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cardLinks {
    border-left: 0.5px solid var(--white-20);
    padding: 20px;
}

/* Transitions */

.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 350ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 350ms;
}

.fadeLong-enter {
    opacity: 0;
}
.fadeLong-enter-active {
    opacity: 1;
    transition: opacity 1000ms;
}
.fadeLong-exit {
    opacity: 1;
}
.fadeLong-exit-active {
    opacity: 0;
    transition: opacity 1000ms;
}

.component {
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: opacity 0.3s ease;
    position: absolute;
}

.component.on {
    opacity: 1;
    width: 100%;
    height: calc(100% - 65px);
    top: 65px;
    padding: 0;
    /* padding: 60px 30px 30px 30px; */
}

/* Page Style */

#sortBtns {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 3;
}

.sortBtn {
    float: left;
    margin: 0 30px 30px 0;
    cursor: pointer;
}

.mainPage {
    padding: 30px 30px 30px 30px;
}

/* .blockPage.mainPage {
    padding: 0px 0px 30px 0px; 
    } */

.block {
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 20px 40px;
    width: 200px;
    height: 150px;
    display: flex;
    margin: 30px;
}

.listItem {
    /* padding: 0 30px 30px 30px; */
    /* margin: 30px; */
    float: left;
    width: calc(100% - 60px);
}


.homeLeftNav, .leftNavGroup {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.leftNavGroup {
    width: calc(100% - 4vw);
    padding: 0 2vw 2vw 2vw;
    /* padding: 0 4%; */
}

.leftNavGroup {
    border-bottom: 1px solid var(--white-20);
}

.groupLabel {
    /* font-size: 13px; */
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
    color: rgba(255,255,255,0.3);
}

#projectColumn {
    float: left;
    width: 38%;
}

.project {
    padding: 20px;
    border: 1px solid rgba(255, 255, 255.05);
    cursor: pointer;
    float: left;
    width: calc(100% - 40px);
    margin: 0 0 20px 0;
    background: #262928;
    position: relative;
    z-index: 2;
}

.projectLink {
    float: right;
}

.projectBox .stages {
    float: left;
    /* width: 260px; */
    opacity: 1;
    height: 0;
    overflow: hidden;
    /* position: relative; */
    padding: 0 20px 0 20px;
    transform: translateY(-100px);
    z-index: 1;
    width: calc(100% - 40px);
    border: 1px solid rgba(255, 255, 255.05);
    transition: all 0.3s ease;
}

.projectBox.on .stages {
    height: auto;
    opacity: 1;
    padding: 0 20px 20px 20px;
    transform: translateY(-22px);
}

.subheading p {
    float: left;
    margin: 0 20px 0 0;
}

.stage {
    float: Left;
    width: calc(100% - 0px);
    padding: 10px 0px 0 0;
    margin: 0;
    font-size: 13px;
}

.stage svg {
    float: left;
    padding: 10px 0 0 0;
}

.stage rect.fill {
    fill: rgba(255, 255, 255, 0.25);
    stroke: none;
}

.stage rect.outline {
    fill: none;
    stroke-width: 2;
    stroke: rgba(255, 255, 255, 1);
}

/* Timeline */

#timeColumn {
    /* float: right; */
    width: 38%;
    position: fixed;
    right: 20px;
    top: 65px;
    overflow-y: scroll;
}

#timeColumn svg {
    overflow-y: scroll;
    /* float: right; */
}

.timeLine {
}

/* Form Page */

.backBtn {
    padding: 0 0 30px 0;
    float: left;
    width: 100%;
}

.fieldSet {
    width: 14%;
    min-height: 500px;
    padding-right: 6%;
    float: left;
}


.fieldRow {
    margin: 0 0 15px 0;
    float: left;
    width: 100%;
    text-align: left;
    height: auto;
    /* z-index: 1; */
    /* position: relative; */
}

.fieldRow.off {
    z-index: -1;
    height: 0;
    overflow: hidden;
}

.full {
    float: left;
    width: 100%;
}

.label {
    float: left;
    width: 100px;
}

.textBox {
    max-width: 50%;
    clear: left;
    margin: 10px 0 0 0;
}

.fieldRow input {
    font-family: "Roboto", sans-serif;
    width: calc(100% - 20px);
    background: rgba(255, 255, 255, 0.75);
    padding: 10px;
    border: 1px solid #fff;
}

.settingsRow input.readOnly {
    background: none;
}

.fieldRow input[type="number"] {
    width: 40px;
    /* background: none; */
    /* color: #fff; */
}

.fieldRow input::placeholder {
    color: #444;
}

.heading {
    float: left;
    width: 100%;
    margin: 0 0 20px 0;
}

.heading .fieldRow input {
    font-size: 24px;
    background: none;
    border: none;
    color: #fff;
    width: 100%;
    padding: 5px 0 5px 0;
    /* border-bottom: 1px solid #fff; */
}


.fieldRow input#sectionTitle {
    font-size: 14px;
    /* background: none; */
    /* border: none; */
    color: #fff;
    width: calc(100% - 10px);
    padding: 5px;
    margin-top: 5px;
    height: calc(100% - 10px);
    /* border: 1px solid rgba(255,255,255,0.2); */
}

.fieldRow input#sectionTitle::placeholder {
    color: #aaa;
}


.heading .fieldRow input[type="checkbox"],
.fieldRow input[type="checkbox"] {
    width: auto;
}

.heading .fieldRow input::placeholder {
    color: #fff;
}

.heading .fieldRow input:focus::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.switch {
    position: relative;
    display: inline-block;
    z-index: 1;
    vertical-align: top;
    height: 22px;
    width: 50px;
    border-radius: 11px;
    background: #06ac47;
    color: rgba(255,255,255,0.5);
    cursor: pointer;
    transition: all 0.3s ease;
}

.switchLabel {
    position: relative;
    left: 5px;
    top: 4px;
    font-size: 13px;
}

.switch.off {
  background: #D93025;
}

.switch.off .switchLabel {
  position: relative;
  left: 26px;
}

.dot {
    background: #fff;
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    left: -1px;
    top: -1px;
    transform: translateX(27px);
    transition: transform 0.4s, box-shadow 0.4s;
    box-shadow: -4px -4px 8px var(--light-shadow-2, transparent),
        4px 4px 8px var(--shadow, transparent);
}

.switch.off .dot {
  transform: translateX(0px);
}

.addBtn {
    color: #fff;
    z-index: 3;
    background: none;
    border: 1px solid #fff;
    border-radius: 4px;
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    font-size: 13px;
}

.addBtn.on {

}


.fieldRow input#fileUpload, input#popUpload {
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;
    float: left;
    overflow: hidden;
}

ul.dropDown, ul.dropDown li ul, ul.dropDown li {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.dropDown li.mediaSelect, ul.dropDown li.animSelect {
    margin-bottom: 5px;
    cursor: pointer;
    /* opacity: 0.5;
    transition: all 0.2s ease; */
}

/* ul.dropDown li.mediaSelect:hover {
    opacity: 1;
} */

#publicAnim .dropDownBtn {
    z-index: 12;
    position: relative;
}

ul.mediaDropDown {
    opacity: 1;
    height: auto;
    border: 1px solid #fff;
    transition: border 0.2s ease;

}

.animationBtn {
    padding-top: 20px;
}


#publicAnim ul.mediaDropDown {
    z-index: 11;
    position: relative;
    background: var(--mid-grey2);
    margin-bottom: 50px;
}

#publicAnim ul.mediaDropDown.off {
    margin-bottom: 0;
}

#publicAnim .dropDown {
    height: auto;
}

#publicAnim .dropDown.off {
    height: 0;
    margin-bottom: 0;
}

ul.mediaDropDown li {
    padding: 0 10px 5px 10px;
}

ul.mediaDropDown.off {
    opacity: 0;
    height: 0;
    overflow: hidden;
    border: 1px solid transparent;
    transition: opacity 0.3s hidden;
}

.dropDownBtn {
    cursor: pointer;
    float: left;
    width: calc(100% - 22px);
    margin: 0px 0 20px 0;
    border: 1px solid rgba(255,255,255,0.2);
    padding: 10px;
}

.dropDown.inputDropDown.off {
    width: calc(85% - 1px);
    padding: 0;
    border: none;
    margin: 0;
}

.dropDown.inputDropDown {
    /* width: calc(85% - 1px); */
    padding: 0;
    margin: 0 0 20px 0;
    position: relative;
    /* top: -2px;
    left: 1px; */
    width: calc(98% - 2px);
    z-index: 1;
    float: left;
    /* box-shadow: 0 0 10px 0px #fff;  */
    background: #555;
    border-radius: 5px;
}

.dropDown.inputDropDown li.animSelect {
    padding: 0;
    margin: 0;
}

.dropDown.inputDropDown li.animSelect span {
    width: calc(100% - 20px);
    float: left;
    padding: 10px;
    border-bottom: 1px solid #999;
}

.dropDown.inputDropDown li.animSelect span:hover {
    background: rgba(255,255,255,0.2)
}

.dropDown.inputDropDown li.animSelect:last-child span{
    border: none;
}

.addMedia {
    cursor: pointer;
    float: left;
    width: 100%;
    margin-top: 10px;
}

:focus {
    outline: none;
}

.btn {
    padding: 15px 20px;
    /* background: #55a3fa; */
    background: #06ac47;
    color: #fff;
    
    /* border: 1px solid #06ac47; */
    border: none;
    box-shadow: 0 1px 3px rgba(0,0,0,0.3);
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    border-radius: 4px;
    transition: background 0.3s ease;
}

.saveRow .btn {
    /* border: 1px solid #06ac47; */
    /* background:#06ac47; */
    /* border: 1px solid #06ac47; */
    box-shadow: 0 1px 3px rgba(0,0,0,0.15);
}

.btn1 {
    padding: 10px 15px;
    width: calc(100% - 30px);
    /* background: #55a3fa; */
    color: #fff;
    border: 1px solid #fff;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    margin: 10px 0;
    -webkit-font-smoothing: antialiased;
    border-radius: 3px;
    transition: background 0.3s ease;
    font-size: 13px;
}

.btn1 .anim {

}

.btn1.autoWidth {
    width: auto;
    float: left;
}

.btn1:hover {
    background: #55a3fa;
    border: 1px solid #55a3fa;
}

.btn1.off {
    opacity: 0.5;
    pointer-events: none;
}

.btn.unsaved {
    background: #55a3fa;
    box-shadow: 0 4px 8px rgba(0,0,0,0.4);
}

.choiceBtn {
    font-size: 14px;
    padding: 10px 15px;
    background: none;
    color: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    /* border-radius: 2px; */
    float: left;
    margin: 0 10px 0 0;
}

.choiceBtn.on {
    /* background: rgba(255,255,255,0.5); */
    border: 1px solid rgba(255, 255, 255, 1);
    color: #fff;
}

/* Canvas */

#canvasRow {
    margin: 0 0 0 0;
    position: fixed;
    bottom: 2%;
    left: 18%;
    width: 67%;
    /* display: none; */
}

.canvasPage {

}

.canvasContainer {
    position: absolute;
    left: calc(4% + 210px);
    width: calc(92% - 460px);
    padding: 0 2% 4% 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(96% - 140px);

}

#canvasPreview {
    border: 1px solid #fff;
    display: block;
    float: left;
    width: 100%;
    height: 200px;
    margin-left: 1px;
}

.canvasSwiper {
    /* border: 1px solid rgba(255, 255, 255, 0.75); */
    height: 120px;
    overflow-y: visible;
}




.canvasFormWrap {
  width: calc(100% - 2px);
  display: flex;
  justify-content: flex-start;
  align-items: center; 
  padding-top: 40px;
}

.canvasForm {
    float: left;
    /* width: calc(62% - 2px); */
    height: 500px;
    margin: 0 0 0 0;
    border: 1px solid #fff;
    display: block;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease;
    overflow: hidden;
}

.clipPath {
    width: 100%;
    height: 100%;
    clip-path: inset(0 0 0 0);
}

.showFull .clipPath {
    clip-path: inset(-100% -100%);
}

.canvasForm.showFull {
    border: 1px solid rgba(255,255,255,0);
    overflow: visible;
}

.canvasForm.aspectRatio_0 {
    width: 100%;
  }

.canvasForm.aspectRatio_1, .tabBtns.aspectRatio_1 {
  width: calc(500px * 1.78);
}

.canvasForm.aspectRatio_2, .tabBtns.aspectRatio_2 {
  width: calc(500px * 1.43);
}




.canvasForm.aspectRatio_3 {
  width: calc(500px * 1.333);
}

/* Drag and Drop */

.draggable {
    /* width: 372px;
    height: 248px; */
    /* position: absolute; */
    display: flex;
    cursor: grab;
    width: 100%;
    height: 100%;
   
    z-index: 2;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.draggable.text {
    align-items: flex-start;
    overflow: visible;
}

.react-draggable {
    /* transition: transform 0.3s ease; */
}

.canvas-card {
    width: 100%;
    height: 100%;
    overflow: visible;
    /* outline: 1px solid white; */
}


.draggableImage img {
  transition: all 0.3s ease;
  object-fit: cover;
  width: 100%;
  height: 100%;
  /* object-fit: none;
  object-position: 50% 50%; */
}

/* .draggableInner{} */

.canvas-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    /* transform-style: preserve-3d; */
}

.showSettings .canvas-card .canvas-card-inner, .showSettings.canvas-card .canvas-card-inner {
    transform: rotateY(180deg);
    z-index: 10;
    position: relative;
}

.showSettings .canvas-card-front {
    z-index: -1;
    /* position: relative; */
}

.showSettings .canvas-card-back {
    z-index: 10;
}

.canvas-card-front,
.canvas-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.canvas-card-front {
    /* background-color: #bbb; */
    color: black;
}

.canvas-card-back {
    background-color: rgb(100,100,100);
    color: white;
    transform: rotateY(180deg);
    text-align: left;
    padding: 20px;
    width: calc(100% - 40px);
    max-height: calc(100% - 40px);
}


.sectionRows {
    float: left;
    width: 100%;
    margin: 30px 0 0 0;
    position: relative;
    z-index: 3;
}

#downArrow {
    float: right;
    
}

#downArrow path{
    /* transition: transform 0.3s ease; */
}

.saveRow {
  margin: 30px 0 30px 0;
}

.canvas-card-corner {
  position: absolute;
  width: 0;
  height: 0;
  /* left: calc(100% - 30px); */
  left: 0;
  top: calc(100% - 30px);
  border-bottom: 30px solid rgba(0,0,0,0.2);
  border-right: 30px solid transparent;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* transition: all 0.3s ease; */
}

.canvas-card-corner.close-corner {
  left: 0;
  border-left: none;
  border-right: 30px solid transparent;
}


/* Resizeable */

.react-draggable {
    display: flex;
    /* border: 1px solid transparent; */
    transition: border 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease;
}

.react-draggable-dragging, .react-draggable.react-draggable-dragging:hover{
    /* border: 1px solid rgba(255,255,255,0.5); */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); */
}


.react-draggable.react-draggable-dragging {
    z-index: 10;
    opacity: 0.9;
}

.react-draggable.showSettings, .react-draggable.setOnTop {
    z-index: 10;
    opacity: 1;
}


  .imgDefault {
      width: auto;
      height: 100%;
  }


  .draggableSettings p {
    font-size: 12px;
    width: 100%;
    float: left;
    margin: 0 0 10px 0;
    padding: 0;
  }

  .canvasLabel {
      /* font-weight: bold; */
      font-size: 12px;
      width: 80px;
      float:left;
      width: 100%;
      line-height: 24px;
      opacity: 0.75;
      text-transform:capitalize;
  }

  .draggableSettings input {
      max-width: 60px;
  }

  #addSection {
      height: 100%;
      /* width: 200px; */
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin: 5px 0 0 0;
      float: right;
      cursor: pointer;
  }

  .sectionTitle {
     margin-bottom: -35px;
     position: relative;
     float: left;
     padding: 5px;
     font-size: 13px;
  }

  .backgroundThumb {
     width: 100%;
     height: auto;
      float: left;
      position: relative;
      z-index: 1;
  }

  

  .deleteBtn {
      cursor: pointer;
      font-size: 13px;
      margin: 40px 0 0 0;
      float: right;
      /* width: 50%; */
  }

  .deleteBtn.btn {
    background: #D93025; 
    padding: 10px 10px;
    font-weight: 400; 
  }
 

  .listPage {
    font-size: 14px;
  }

  .row {
      float: left;
      width: 100%;
      margin: 0px 0 10px 0;
      padding: 5px 0 5px 0;
    display: flex;
    flex-direction: column;
  }

  .addNarrative {
    cursor: pointer;
    margin: 0 0 20px 0;
  }

  .narrative.row p{
      margin: 0;
      padding: 0;
  }

  /* .narrative.row {
      width: auto;
      padding: 5px 20px 5px 5px;
      margin: 0 0 10px 0;
      clear: left;
      border: 1px solid rgba(255,255,255,0.5);
  } */


  #manifestColumn {
      float: right;
      position: fixed;
      top: 40px;
      right: 0;
      padding: 0 0 0 10px;
      z-index: 10;
      height: calc(100vh - 400px);
      width: 290px;
      /* height: 100%; */
     background: #212121;
      transition: transform 0.3s ease;
      z-index: 5;
  }


  #manifestColumn.slide {
      transform: translateX(240px);
  }

  #manifestColumn  h4{
      margin: 0 0 0px 0;
      padding: 0;
      overflow-wrap: break-word;
  }

  .stateSettingsRow {
      margin: 0 0 20px 0;
      padding-top: 10px;
      float: left;
      width: 100%;
      border-top: 0.5px solid rgba(255,255,255,0.1);
  }

  .bottomRule {
    border-bottom: 0.5px solid rgba(255,255,255,0.1);
    padding-bottom: 20px;
  }

  #state_row_0.stateSettingsRow {
    border: none;
  }

  .stateSettingsRow input[type="file"] {
      padding-top: 10px;
  }
  .stateSettingsRow .settingsRow {
      margin-top: 10px;
  }

  .fileNameGroup {
    float: left;
    width: 100%; 
    margin: 10px 0 10px 0;
    overflow-wrap: break-word;
  }

  .fileName {
    overflow-wrap: break-word;
    line-height: 100%;
    padding: 0;
    margin: 0 0 0px 0;
    width: 100%;
    float: left;
    font-size: 12px;
    /* color:  */
  }

  .settingsList {
      float: left;
      width: 100%;
    position: relative;
    z-index: 2;
      opacity: 1;
      height: 620px;
      overflow:hidden;
      overflow-y: scroll;
  }

  /* .settingsList .off {
      opacity: 0;
      height: 0;
      overflow: hidden;
  } */

  #manifestColumn p {
    cursor: pointer;
    float: left;
    width: 100%;
    margin: 0 0 0 0;
  }
  
  .settingsRow {
      float: left;
      width: 100%;
      margin: 0 0 10px 0;
  }

  .settingsRow.disabled {
      opacity: 0.2;
      cursor: default;
  }

  .settingsRow.disabled input {
    cursor: default;
  }

  .settingsRow.halfRow {
      width: 50%;
  }

  .settingsRow.thirdRow {
    width: 30%;
    margin: 0 3% 0 0;
}

.settingsRow.thirdRow input {
    width: 80%;
    margin: 0 5% 0 0;
}

.settingsRow input, .leftNav input {
    width: calc(98% - 8px);
    margin: 0 0 0 0;
    position: relative;
    z-index: 2;
    --webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 3px;
    padding: 2px 4px;
    background: #555;
    color: #ddd;
}

.settingsRow input::placeholder {
    color: #ddd;
}

.settingsRow input#fileReplace {
    border: none;
    background: none;
}

.settingsRow input.on {
   border: 1px solid rgb(3, 127, 212);
}

.settingsRow input.editing {
    transition: all 0.2s ease;
    background: rgba(3, 127, 212,1)
}


  .settingsRow.halfRow input {
      width: 80%;
      margin: 0 5% 0 0;
  }
  
  .settingsRow.halfRow input.sm {
    width: 40%;
    margin: 0 0 0 5%;
  }

  .settingsRow.halfRow input[type="number"] {
    width: 50px;
  }

  .settingsRow.halfRow.rightRow input,   .settingsRow.halfRow.rightRow .canvasLabel, .settingsRow.halfRow.rightRow .switch{
      margin: 0 0 0 5%;
  }



  #manifestColumn .react-draggable-dragging, #manifestColumn .react-draggable.react-draggable-dragging:hover{
    /* border: 1px solid rgba(255,255,255,0.5); */
    box-shadow: none;
}   
.showSettings, #manifestColumn .react-draggable {
    /* float: left;
      width: 100%; */
}

  .mediaSettings, .mediaSettings.off  {
      opacity: 0;
      height: 0;
      overflow: hidden;
      float: left;
      padding: 0;
      width: 100%;
  }

  /* .mediaSettings div {
      display: block;
  } */

  .mediaSettings.off div {
      display: none;
  }



  .mediaFile {
      /* height: auto; */
      /* float: left; */
      margin: 10px 0% 10px 0;
      cursor: pointer;
    
      display: inline-block;
      width: 100%;
      word-wrap: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
    -webkit-hyphenate-character: "";
    width: 260px;
  }

  .layerIconContainer, .visibility {
    opacity: 0.5;
    transition: opacity 0.3s ease;
  }

  .layerIconContainer:hover, .mediaFile.on .layerIconContainer, .mediaFile.on .visibility, .visibility:hover {
    opacity: 1;
}

  .mediaFile .iconField {
    display: grid;
    grid-template-columns: 1fr 30px 30px;
    
  }
  

  span.lock {
    margin-left: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  span.lock.on, .mediaFile .iconField:hover span.lock, .locked span.lock {
    opacity: 1;
  }

.layerIconContainer {
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: 10px
}

    .settingsOn .mediaFile  {
        opacity: 0;
        z-index: -1;
        height: 0;
        overflow: hidden;
    }

    .settingsOn .react-draggable {
        /* position: fixed;
        top: 0; */
        transform: translate(0, 30px) !important;
    }

    .settingsOn .showSettings {
        min-width: 250px;
    }

    /* .settingsOn .showSettings .mediaFile{
        opacity: 1;
        z-index: 4;
        height: auto;
        overflow: visible;
        float: left;
        display: block;
    } */

    .settingsOn .mediaSettings.showSettings.on {
        opacity: 1;
        height: 100vh;
        z-index: 10;
        position: fixed;
        top: 50px;
        right: 0;
        overflow: visible;
        padding: 1.5% 0px 5px 0px;
        background: #2A2A2B;
        float: left;
        width: calc(100% - 0);
        display: block;
        max-width: 300px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.45);
        }

        .settingsOn .mediaSettings.showSettings.on.locked {
            pointer-events: none;
            opacity: 0.3;
        }
    
    .mediaThumb {
        transition: all .2s ease-in-out;
    }
    
    .inFocus .mediaThumb {
        opacity: 0.3;

    }

    .inFocus .focus.mediaThumb {
        opacity: 1;
        outline: 2px solid #ec008c;
        box-shadow: 0 1px 6px rgba(0,0,0,0.3);

    }

    .inFocus .focus.mediaThumb.text {
        outline: 1px solid rgba(255,255,255,0);
        box-shadow: none; 

    } 
    /* .inFocus .focus.mediaThumb.newText {
        outline: 0.5px solid rgba(0,0,255,0.5);
    } */
    

    .focus .resizer {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid #ec008c;
        background: white;
        position: absolute;
        z-index: 3;
        pointer-events: none;
    }

    .inFocus .focus.multi.mediaThumb {
        outline: 1px solid #ec008c;
    }

    

    .focus.multi .resizer, .focus.text .resizer, .focus.bkgLayer .resizer  {
        opacity: 0;
    }

    .focus.bkgLayer {
        cursor: default;
    }

    /* .focus .resizer:active {
        background: #ec008c;
    } */

    .resizer.topLeft {
        top: -7px;
        right: calc(100% - 7px);
    }

    .resizer.topRight{
        top: -7px;
        left: calc(100% - 7px);
      
    }


    .resizer.bottomLeft {
        top: calc(100% - 7px);
        left: -7px;
    }

    .resizer.bottomRight{
        top: calc(100% - 7px);
        right: -7px
    }

    .mediaThumb.bounce {
        z-index: 10;
        opacity: 1;
        transform: scale(1.02);
        box-shadow: 0 1px 6px rgba(0,0,0,0.1);
    }
    

    .closeFormBtn {
        float: left;
        /* width: 50%; */
        /* margin-top: 40px; */
        cursor: pointer;
    }

    .closeFormBtn.btn {
        padding: 10px 10px;
        font-weight: 400;
    }

    .sectionTools {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 2;
        /* display: none; */
    }

    .sectionHeading .sectionLabel {
        width: 100px;
        float: left;
        font-size: 13px;
        padding: 4px 0 0 0;
    }

    .sectionHeading input[type="number"], .sectionHeading input[type="text"] {
        float: left;
        width: 50px;
        font-size: 13px;
        margin: 0 0 0 0;
        /* background: none;
        border: 1px solid rgba(255,255,255,0.2); */
        color: #fff;
        padding: 5px;
    }
    .sectionHeading input[type="number"]::placeholder, .sectionHeading input[type="text"]::placeholder {
        color: #fff;
    }


    .settingsHeading {
        padding: 5px 0 0px 0;
        margin: 25px 0 0px 0;
        float: left;
        width: 90%;
        font-size: 13px;
        color: rgba(255,255,255,0.3);
        /* border-top: 1px solid rgba(255,255,255,0.2); */
    }

    .deleteSection {
        float: left;
        /* padding: 10px 0 0 0; */
        margin: 30px 10px 0px 0;
        width: auto;
        clear: left;
        cursor: pointer;
        /* opacity: 0.5; */
        /* border-top: 1px solid rgba(255,255,255,0.2); */
    }

    .deleteSection.btn {
        background: #D93025;
        padding: 10px 10px;
        font-weight: 400;
    }

    .leftArrow {
        width: auto;
        /* position: fixed; */
        top: 0px;
        left: 0px;
        height: 14px;
        z-index: 3;
        cursor: pointer;
        /* position: relative; */
        position: relative;
        z-index: 4;
    }

    .leftArrow img{
        width: auto;
        height: 14px;
        margin: 0 5px -2px 0;
    }

    .formPage {
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .formPage.ready {
        opacity: 1;
    }

    .details {
        float: left;
        /* width: 100%; */
        margin: 35px 0 0 0;
        padding-top: 10px;
        opacity: 0.3;
        font-size: 12px;
        border-top: 1px solid rgba(255,255,255,0.5);
        position: fixed;
        bottom: 1vw;
        left: 2vw;
    }

    .details a {
        width: 100%;
        float: left;
        margin: 0 0 10px 0;
    }

    .existingFile {
        margin: 20px 0;
        font-size: 13px;
        cursor: pointer;
    }

    .existingFileRow {
        cursor: pointer;
    }


    #menuBtn {
        position: fixed;
        right: 8px;
        top: 8px;
        transform: scale(-1,1);
        width: 24px;
        height: auto;
        cursor: pointer;
        z-index: 3;
    }
    #menuBtn svg {
        width: 100%;
        height: auto;
    }

    .deleteX {
        float: right;
        width: 10px;
        height: auto;
    }

    .deleteX img {
        width: 100%;
        height: auto;
    }

    .deleteSectionX {
        position: absolute;
        /* float: right; */
        margin-bottom: -20px;
        top: 20px;
        z-index: 4;
        
    }

    .deleteSectionX img{
        width: 12px;
        height: auto;
        padding: 3px;
        background: rgba(255,255,255,0.25);
    }

    .canvasFrame div {
        width: 100%;
        text-align: right;
    }

    .fullRow {
        flex-direction: column;
    }

    .swiper-container {
        border-left: 1px solid rgba(255,255,255,0.1);
        border-right: 1px solid rgba(255,255,255,0.1);
    }

    .canvasFrame {
        display: block;
        float: left;
        width: 300px;
        height: 120px;
        min-height: auto;
        margin-left:0;
        cursor: pointer;
        transition: background 0.3s ease;
       
    }
    
    .canvasFrame.on .canvasThumbBorder {
      background: rgba(255,255,255,0.2);
    }


    .canvasFrame div.cueLine {
        height: 100px;
        top: 0px;
        left: 0px;
        width: calc(100% - 1px);
        text-align: left;
        border-left: 1px solid #F3C867;
        position: absolute;
        z-index: 3;
    }

    .canvasThumbBorder {
        float: left;
        position: relative;
        top: 0px;
        margin-top: 20px;
        height: 80px;
        border-right: 1px solid rgba(255, 255, 255, 0.5);
    }


    .canvasThumbBorder img{
        width: 100%;
        height: auto;
    }

    .canvasFrame div.cueLineTitle {
        font-size: 11px;
        padding: 0 0 5px 5px;
        color: #F3C867;
        width: 100%;
        text-align: left;
        letter-spacing: 0.5px;
    }

    .lockIcon {
        margin: 20px 0 0 0;
        cursor: pointer;
    }

    .lockIcon img{
        width: 16px;
        height: auto;
    }

    .sectionHeading {
        margin: 0 0 0 0;
    }

    #menuBtn.closeBtn {
        z-index: 11;
    }

    #menuBtn.closeBtn img{
        width: 15px;
        height: auto;

    }

    .swiper-slide {
        opacity: 0.5;
        transition: opacity 0.3s ease;
    }

    .swiper-slide.on {
        opacity: 1;
    }


    .settingsGroup {
        border-bottom: 1px solid rgba(255,255,255,0.2);
        display: inline-block;
        padding: 0px 20px 10px 20px;
        margin: 0 0 10px 0;
        width: calc(100% - 40px);
    }

    .settingsGroup.stateGroup {
        padding-bottom: 20px;
    }

    .settingsGroup.off, .settingsRow.off {
        display: none;
    }

    .formatLock {
        display: flex;
        gap: 10px;
    }

    .formatLock svg {
        opacity: 0.3;
    }
    
    .formatLock svg.on {
        opacity: 1;
    }

    #saveRow {
        position: fixed;
        bottom: 0px;
        right: 0px;
        width: 260px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding: 20px;
        gap: 20px;
        backdrop-filter: blur(2px);
        background-color: rgb(42, 42, 43, 0.5);
        margin: 0;
        z-index: 10;
    }

    /* #saveRow .btn {
        background: var(--grey);
    } */

    #saveRow .btn {
        /* background: var(--light-blue2); */
        background: var(--white-20);
    }

    #saveRow .btn.unsaved {
        background: var(--light-blue2);
    }


    .settingsRow .iconField.rightNavDeleteBtn {
        width: auto;
        float: none;
    
    }
    
    .groupLabel {
        /* width: 100%;
        float: left; */
        width: 100%;
        display: inline-block;
        margin: 0 0 10px 0;
    }

    #manifestColumn  .groupLabel p, .moduleSettings .groupLabel p{
        color: rgba(255,255,255,0.3);
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 1px;
        margin: 0;
        padding: 0;
        cursor: auto;
    }

    #animDropdown ul.on {
        padding-bottom: 10px;
        margin-bottom: 20px;
        
    }
    #animDropdown .dropDownBtn, ul.dropDown li.animSelect {
        font-size: 12px;
        text-transform: capitalize;
    }

    .mediaSettingsInner {
        float: left;
        width: 100%;
        height: calc(100vh - 130px);
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .animBtn {
        float: left;
        margin: 0 6px 10px 0;
        padding: 5.25px;
        border-radius: 3px;
        border: 1px solid rgba(255,255,255,0.3);
        font-size: 12px;
        cursor: pointer;
        transition: all 0.3s ease;

    }

    .animBtn.on, .animBtn:hover, .dropDownValue {
        background: rgba(0,0,0,0.1);
        color: #55a3fa;
    }

    .clearValue {
        float: right;
        width: 10px;
        position: relative;
        /* top: -4px; */
        font-size: 18px;
        cursor: pointer;
        /* border: 1px solid var(--white-20); */
        border-radius: 20px;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white-20)
    }

    .clearValue .iconSm {
        fill: white;
        height: 12px;
        width: 12px;
        opacity: 0.3;
    }

    .animLabel {
        font-size: 12px;
        text-transform: capitalize;
        margin: 0 0 10px 0;
        width: 100%;
        display: inline-block;
    }

    .animRow.off {
        opacity: 0;
        height: 0;
        overflow: hidden;
    }

    .animObj {
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(255,255,255,0.1);
    }

    .newAnimObj {
        opacity: 0;
        height: 0;
        overflow: hidden;
        transition: opacity 0.3s ease;
    }

    .newAnimObj.on {
        height: auto;
        opacity: 1;
    }

    .newAnimBtn {
        width: calc(100% - 16px);
        text-transform: capitalize;
    }

    .clearValue:hover {
        color: #D93025;
    }

    .icon {
        width: 16px;
        height: 16px;
        object-fit: contain;
    }

    .iconSm {
        width: 8px;
        height: 8px;
        object-fit: contain;
    }

    .visibility {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .iconMd {
        width: 24px;
        height: auto;
    }


    .iconLg {
        width: 32px;
        height: auto;
    }

    .preview {
        transition: all 0.3s ease;
    }

    .preview:hover {
        opacity: 0.8;
    }

    .hover {
        cursor: pointer;
        float: left;
        width: 100%;
    }

    #fileReplace {
        padding: 10px 0 0 0;
    }

    #loginFormPage {
        width: 100vw;
        height: calc(100vh - 90px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    
        /* background: var(--dar); */
      }

      #logInLogo {
        width: 40%;
        height: auto;
        padding-bottom: 10px;
      }

      #logInLogo h1 {
        padding: 10px 0;
        font-weight: 200
      }

      #logInLogo svg {
        width: 100%;
        height: auto;
      }

      #logInLogo svg path {
        fill: white;
      }
    
    .loginForm {
    width: 100%;
    /* height: 100%; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    max-width: 400px;
    /* max-height: 400px; */
    gap: 25px;
    border: 1px solid var(--white-20);
    border-radius: 20px;
    background: var(--mid-grey3);
    box-shadow: 0 12px 20px 0px rgba(0, 0, 0, 0.3); 
    padding: 80px 0;
    top: -50px;
    position: relative;
    }

    .loginForm .formField {
        width: 61%;
    }

    .loginForm input {
    width: calc(100% - 8px);
    margin: 0;
    padding: 4px;
    border-radius: 4px;
    border: 2px solid black;
    font-size: 16px;
    }

    .loginForm input.submitBtn {
    width: 100%;
    border: 1px solid var(--light-blue2);
    background: var(--light-blue2);
    transition: all 0.3s ease;
    }

    .loginForm input.submitBtn:hover {
       transform: translateY(-3px);
    }

    .reset {
        /* color: var(--light-blue2); */
        margin: 20px 0 0 0;
        border-bottom: 1px solid white;
    }

    #logo {
        position: fixed;
        top: 12;
        left: 70px;
        width: 80px;
        height: auto;
    }

    #logo svg{
        width: 100%;
        height: auto;
    }

    #logo path {
        fill: white;
    }

    .beta {
        position: fixed;
        bottom: 2vw;
        left: 80px;
        font-size: 10px;
        letter-spacing:2px;
        font-weight: 700;
        border: 1px solid white;
        border-radius: 40px;
        padding: 4px 8px;
        background: rgba(33, 33, 33, 0.3);
        z-index: 3;
    }

    .betaText {
        font-size: 10px;
        letter-spacing:2px;
        font-weight: 700;
        border: 1px solid white;
        border-radius: 40px;
        padding: 6px 10px;
        line-height: 100%;
        margin: 0 0 12px 0;
        display: inline-block;
    }

    #showSettings {
        position: fixed;
        right: 0;
        top: 0;
        padding: 0 20px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border-left: 1px solid var(--white-20); */
    }
    
    .userBtns {
        padding: 0px 0px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 0px;
        width: 180px;
        position: fixed;
        top: 50px;
        right: 0;
        border: 1px solid var(--white-20);
        border-right: none;
        background: #212121;
        box-shadow: 0 12px 20px 0px rgba(0, 0, 0, 0.3); 
    }
    
    .light .userBtns {
        border: 1px solid var(--black-20);
        background: white;
    }
    
    .userBtn {
        cursor: pointer;
        text-transform:capitalize;
        margin: auto;
        width: 100%;
        border-bottom: 0.5px solid var(--white-20);
        height: 50px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        
    }

    .userBtn.lower {
        text-transform: lowercase;
    }

    .userBtn:last-child {
        border: none;
    }

    .userBtn.mode .iconField{
        opacity: 0.3;
        cursor: default;
    }
    
    .username {
        background: var(--white-20);
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        text-transform: uppercase;
    }
    
    .dark .userBtn path {
        stroke: white;
    }
    
    .dark .userBtn .fill path {
        fill: white;
    }
    
    .userBtn svg {
        height: 20px;
        width: auto;
    }

    .dark .userBtn path {
        stroke: white;
    }
    
    .dark .userBtn .fill path {
        fill: white;
    }
    

    .flex.siteFilters {
        justify-content: flex-start;
    }
    
    .siteFilter .icon {
        width: 32px;
        height: 32px;
        margin: 0;
    }
    
    .siteFilter .icon svg {
        width: 100%;
        height: 100%;
    }
    
    .siteFilter .icon path.background {
        fill: var(--light-blue2);
    }
    
    .siteFilter .icon path.art {
        fill: white;
    }
    
    .siteFilter {
        cursor: pointer;
        padding: 4px 12px 4px 8px;
        margin: 0;
        display: flex;
        align-items: center;
        border-radius: 40px;
        color: white;
        background: rgba(255,255,255,0.1);
        transition: all 0.3s ease;
        min-width: 80px;
        justify-content: space-between;
        border: 1px solid transparent;
        min-height: 28px;
        max-width: 800px;
    }
    
    .siteFilter.ui.on {
        background: var(--lightBlue2);
        color: #fff;
    }
    
    .light .siteFilter.ui {
        color: var(--lightBlue2);
        background: var(--brookfieldBlue-10);
    }

    .siteFilter.searchField {
        gap: 5px;
        justify-content: flex-start;
        margin: 0 0 0 10px;
        min-height: 22px;
  
    }

    .searchField span {
        display: flex;
        align-self: center;
    }

    .siteFilter.searchField .icon {
        height: 20px;
        width: auto;
    }

    .siteFilter input[type="text"].search {
    background: none;
    border: none;
    padding: 0 10.5px;
    caret-color: var(--gold);
    min-width: 300px;
    color: white;
  
}

.siteFilter.ui:has(input[type="text"].search:focus) {
    border: 1px solid white;
}

.siteFilter input[type="text"].search::placeholder {
    color: var(--light-grey);
}

.light .siteFilter input[type="text"].search {
    caret-color: var(--lightBlue2);
    color: var(--lightBlue2)
}


.light .siteFilter.ui:has(input[type="text"].search:focus) {
    border: 1px solid var(--lightBlue2);
}

.light .siteFilter input[type="text"].search::placeholder {
    color: var(--brookfieldBlue-50);
}

.narrativeBtn {
    margin-bottom: 30px;
}

.moduleGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.moduleGrid .gridItem {
    border-radius: 6px;
    padding: 0;
    /* border: 0.5px solid var(--white-20); */
    align-items: center;
    display: grid;
    width: 100%;
    grid-template-columns: 30px 1fr;
    gap: 20px;
}

.gridItem.deleted {
    opacity: 0.5;
}

#narrativeOverviewTools {
    position: fixed;
    top: 0;
    border-left: 1px solid var(--white-20);
    left: 175px;
    z-index: 11;
    height: 50px;
    display: flex;
    align-items: center;
    gap: 20px;
    width: 80vw;
    padding: 0 0 0 20px;
}

.shareBtn {
    width: auto;
    padding: 8px 10px;
   
    /* border: 1px solid var(--light-blue); */
}


.shareModal .textContainer .copyLink {

    width: auto;
    float: left;

}

#narrativeOverviewTools .shareBtn {
    position: fixed;
    right: 80px;
}

.tools {
    display: flex;
    align-items: center;
}

#narrativeTools {
    position: fixed;
    top: 0;
    border-left: 1px solid var(--white-20);
    left: 75px;
    z-index: 11;
    height: 50px;
    display: flex;
    align-items: center;
    gap: 20px;
    width: 70vw;
    padding: 0 0 0 20px;
}

#narrativeTools .iconField, #narrativeOverviewTools .iconField {
    border-right: 1px solid var(--white-20);
    height: 50px;
    padding-right: 20px;
    display: grid;
    grid-template-columns: 16px 1fr;
}

.iconLabel {
    /* font-weight: bold; */
    font-size: 12px;
    /* width: 80px; */
    float:left;
    width: 100%;
    line-height: 24px;
    opacity: 0.75;
    line-height: 120%;
}

.previewBtn {
    position: fixed;
    right: 360px;
    height: 50px;
    display: flex;
    align-items: center;
}

.previewBtnGallery.previewBtn {
    right: 80px;
}

#narrativeOverviewTools .previewBtn {
    right: 180px;
}


.publishBtn {
    position: fixed;
    right: 223px;
    border-right: none;
    border-left: 1px solid var(--white-20);
    padding-left: 20px;
  }

.publishBtn.ready {
    /* background: var(--light-blue2);
    color: white;
    transition: all 0.2s ease; */
}

#narrativeTools h1 {
    position: fixed;
    left: 44vw;
}

/* #narrativeTools h1:not(:empty) {
    background-color:#f7cb00;
    display: inline-block;
    line-height: .8;
    margin-right: 0.5rem;
    padding: 0.1875rem 0.3125rem;
    transform: skew(-10deg);
    color: black;
} */

#fileUpload {
    display: none;
}

#narrativeDetail .leftNav {
   z-index: 3;
   background: var(--dark-grey);
}

.fieldRow .sectionLabel {
    /* margin-bottom: 25px; */
}

.layers {
    /* margin-top:20px; */
}
.pageBtn {
    display: grid;
    grid-template-columns: 70px 1fr;
    transition: border 0.3s ease;
    cursor: pointer;
}

.pageHeadings .pageBtn {
    display: flex;
    flex-direction: column;
   
}

.pageBtnContainer {
    transition: all 0.2s ease;
    height: 30px;
    position: relative;
    z-index: 2;
}

.open .pageBtnContainer{
    height: auto;
}

.pageLink {
}


.pageBtn.on {
    border-bottom: 2px solid white;
}

.pageBtn.mediaFile {
    margin: 0 0 0 0;
    width: 100%;
    opacity: 0.61;
    transition: opacity 0.3s ease, background 0.3s ease;
    /* outline: 1px solid rgba(255,255,255,0.8); */
    /* background: rgba(0,0,255,0.3); */
}

.draggableNative .pageBtn.mediaFile {
    position: absolute;
}

.pageHeadings .pageBtn.mediaFile {
    /* outline: 1px solid rgba(255,0,0,0.8); */
    outline: none;
    height: 30px;
    padding-left: 2vw;
    width: 16vw;
}

.pageHeadings .pageBtn.mediaFile.open {
    height: auto;
}

.pageHeadings .groupFiles .pageBtn.mediaFile {
    /* padding-left: 0; */
    padding-left: 1vw;
    width: 17vw;
}

.groupFiles .pageBtn.mediaFile {
    /* outline: 1px solid rgba(0,255,0,0.5); */
    /* background: rgba(0,255,0,0.3); */
}

.pageBtn.mediaFile.active {
    background: rgba(255,255,255,0.3);
}


.pageBtn.mediaFile.selected {
    background: rgba(255,255,255,0.1);
    /* color: var(--light-blue) */
    
}

.dragLine {
    width: 16vw;
    
    position: relative;
    /* top: 30px; */

    opacity: 0;
    transition: all 0.3s ease;
    height: 28px;

}
.dragLine.top {
    border-top: 2px solid var(--light-blue2);
    border-bottom: none;

}

.dragLine.bottom {

    border-top: none;
    border-bottom: 2px solid var(--light-blue2);
    /* border-top: 2px solid var(--light-blue2); */
}
/* 
.dragLine.on {
    border-top: 2px solid gold;
} */

.draggableNative .groupFilesIn {
    width: 16vw;
}

.react-draggable-dragging .dragLine {
    opacity: 1;
    transition-delay: 100ms;
}

.react-draggable-dragging .groupHeading .iconLg {
    opacity: 0;
}

.pageBtn.mediaFile:hover {
    opacity: 1;
    
}

.allPages {
    transition: all 0.3s ease;
    top: 87vh;
    position: fixed;
    left: 0;
    padding: 20px 2vw;
    background-color: var(--dark-grey);
    width: 13vw;
    height: 0px;
    overflow: hidden;
}

.allPages.open {
    height: 100vh;
    top: 60px;
}

.sectionBtns {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}
.sectionBtn {
   opacity: 0.61;
   transition: opacity 0.3s ease;
}

.sectionBtnContainer {
    display: grid;
    grid-template-columns: 1fr 24px;
}

.sectionBtn:hover {
    opacity: 1;
}

.screenshot {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left center;
}

.pageNav {
    display: flex;
    margin: 40px 0 0 0;
    justify-content: space-between;
    position: fixed;
    bottom: 2vw;
    width: 79%;
}

.defaultUi {
    margin: 20px 0 0 0;
}

.disabled {
    opacity: 0.3;
}

.flip {
    transform: scale(-1, 1);
}

.addNew {
    padding: 0 0 20px 0;
}

.settingsGrid {
    display: grid;
    grid-template-columns: 50% 30% 20%;
}

.scrollingContainer {
    position: fixed;
    left: 0px;
    top: 0;

}

.scrollScreen {
    position: fixed;
    top: 0;
    left: 0;
    height: 150px;
    backdrop-filter: blur(5px);
    width: 17vw;
    z-index: 1;
}

.scrollBar {
    position: fixed;
    left: 17vw;
    top: 180px;
    border-radius: 10px;
    width: 10px;
    height: 74vh;
    background: rgba(255,255,255,0.05);
}

.scrollHandle {
    border-radius: 10px;
    width: 10px;
    height: 50px;
    position: relative;
    background: rgba(255,255,255,0.15);
}

.pageHeadings {
    position: fixed;
    top: 180px;
    left: 0;
    /* padding-left: 2vw; */
    height: 80vh;
    width: 17vw;

}

.pagesContainer {
    /* z-index: 2; */

}

.groupCategories {
    z-index: 2;
    position: relative;
}

.draggablePages, .nonDraggablePages {
    position: fixed;
    left: 2%;
    height: 73vh;
    top: 180px;
    width: 15vw;
}




/* .pagesContainer .draggablePages{
    overflow: visible scroll; 
    height: 200px !important;

} */

/* .menuContainer {
    overflow: hidden;
    overflow-y: scroll;
    height: 200px;
    border: 1px solid fuchsia;
    float: left;
    width: 100%;

} */

.draggableGalleryFiles {
    overflow-y: scroll; 
}

.draggablePages.moduleLeftNav {
    top: 170px;

}


.nonDraggablePages .layerIconContainer {
    clear: left;
    padding: 5px 0 0 0;
    /* padding-bottom: 15px; */
    /* border-bottom: 1px solid var(--white-20); */
}

.draggablePages.draggableHeading {
    top: 260px;
}


.draggablePages.moduleLeftNav.documentNav {
    top: 290px;

}



.rightNav {
    min-height: 100vh;
}

.mainLayers {
    margin-top: 0px;
}

.backgroundImg {
    /* display: none; */

}

.target {
    background-color: blue;
}

.tox .tox-edit-area__iframe {
    background: rgba(255,255,255,0.1) !important;
}

.bkgFile {
    padding: 10px 0 0 0;
    cursor: pointer;
}

.backgroundImg {
    padding: 5px 0 0 0;
}

.canvasText {
    white-space: pre-line;
    cursor: text;
    width: 100%;
    height: 100%;
    display: inline-block;
    caret-color:  var(--light-blue);
}

/* .canvasText::selection {
    background: var(--light-blue2);
    color: white;
}

 */
 /* .canvasText .selection > *::selection {
    background: var(--light-blue2);
    color: white;
   
} */



.canvasText .selection, .canvasText .stl::selection, .canvasText .selection::selection, .canvasText::selection   {
    /* background: var(--light-blue2); */
    background: none;

}

.canvasText .selection {
    background:var(--light-blue2);
}

.focus .canvasText {
    /* text-decoration: underline; */
}

.canvasText.readOnly {
    cursor: pointer;
}

.settingsRowIcon {
    display: grid;
   grid-template-columns: 1fr 40px 1fr;
   /* gap: 10px; */
   /* max-width:190px; */
   max-width: 215px;
   width: 100%;
  
   align-items: center;
   justify-content: center;

}

.settingsRowIcon .settingsRow.halfRow {
    width: auto;
    float: none;
}

.settingsRow .iconField {
    float: left;
    width: 100%;
}

.wysiwyg {
    background: none;
    border: none;
}


.dropDownField {
    opacity: 0;
    position: absolute;
    z-index: 4;
    margin: 30px 0 0 0;
    transform-origin: 0 center;
    clear: left;
}

.dropDownField.on {
    opacity: 1;
    height: auto;
    overflow: visible;
}

.dropDown {
    position: relative;
    float: left;
    width: calc(100% - 70px);
    height: 20px;
}

.dropDownField.taskStatus, .dropDownField.status {
    margin-top: 20px;
}

.dropDown.on {
    opacity: 1;
}

.dropDownChoices {
    border: 1px solid var(--white-20);
    position: absolute;
    padding: 3px 6px;
    border-radius: 5px;
    background: rgba(0,0,0,0.2);
    backdrop-filter: blur(10px);
    box-shadow: 0 12px 20px 0px rgba(0, 0, 0, 0.3);  
    left: 0px;
    width: auto;
    top: -20px;
    height: 0;
    overflow: hidden;
    overflow-y: scroll;
    max-height: 35vh;
}

.dropDownChoices::-webkit-scrollbar, .dropDownChoices::-webkit-scrollbar-track {
    width: 0;
    border-radius: 0;
  }

.dropDownField.taskStatus .dropDownChoices, .dropDownField.status .dropDownChoices {
    top: 15px;
}

.dropDownChoices.status {
    width: 40px;
}

.dropDownChoice {
    display: flex;
    flex-direction: column;
    padding: 4px 8px;
    margin: 4px 0;
    font-size: 14px;
    border-radius: 5px;
}

.dropDownChoice.gridChoice {
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: 15px;
    align-items: center;
}

.dropDownChoice:last-child { 
    border-bottom: none;
}

.dropDownChoice.on, .dropDownChoice.on:hover {
    background: var(--light-blue2);
    color: #fff;
}


.status .dropDownChoice.on, .dropDownChoice.on:hover, .statusWithName .dropDownChoice.on{
    background: var(--light-grey);
    color: var(--dark-grey);
}

.dropDownChoice:hover {
    background: var(--white-20);
}


.light .dropDownChoice:hover {
    background: var(--light-grey);
}

.fontBtn {
    text-transform: capitalize;
}


.smCancel {
    position: relative;
    margin-left: -22px;
    top: 2px;
}

.smCancel.textarea {
    left: 0;
    top: 0;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.smCancel svg {
    width: 16px;
    height: auto;
}


.zoomBtns {
    position: fixed;
    right: 70px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    display: none;
    /* border-left: 1px solid var(--white-20); */
}

.zoom {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid var(--white-20);
    height: 50px;
}


.displayBtns {
    position: fixed;
    right: 70px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-left: 1px solid var(--white-20);
}



.displayFormatBtns {
 
    z-index: 4;
}

.displayName {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid var(--white-20);
    height: 50px;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.3s ease;
}
.displayName.on, .displayName:hover {
  opacity: 1;
}

.displayName path {
    fill: white;
}



#publicAnim {
    padding-bottom: 30px;
}

#publicAnim .dropDown {
    width: 100%;
}

#publicAnim .groupLabel {
    padding-bottom: 10px;
}

.colorPicker {
    opacity: 0;
    height: 0;
    z-index: -1;
    overflow: hidden;
    position: absolute;
    margin-top: 40px;
}

.colorChip {
    width: 16px;
    height: 16px;
    border: 1px solid var(--white-20);
    border-radius: 3px;
}



.grid3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.grid6 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}

.settingsRow .fontBtn {
   
}

.tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid var(--white-20);
    width: 210px;
    padding: 0 2%;
    left: 0;
    height: 50px;
    top: 50px;
    position: fixed;
    justify-content: flex-start;
}

.tab {
    opacity: 0.5;
    transition: all 0.3s ease;
    font-size: 13px;
}

.tab.on {
    opacity: 1;
}

.images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.thumbnail {
    /* border: 1px solid var(--white-20); */
    margin: 0;
    padding: 0;
    /* background: rgba(255,255,255,0.1); */
    
    /* max-width: 400px; */
}

.learnRow .thumbnail {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.learnCaption .icon {
    padding-left: 5px;
    position: relative;
    top: -20px;
}

.thumbnail img, .thumbnail figure {
    aspect-ratio: 1.78/1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    float: left;
    border-radius:5px;
    border: 1px solid var(--white-20);
    
}

.images.documents {
    width: 100%;
}

.documents .thumbnail {
    width: 100%;
    aspect-ratio: 1.78/1;
}

.documents .thumbnail img {
    width: 100%;
    height: 100%;
}

.thumbnail figure {
   
}

.thumbnailCaption {
    float: left;
    padding: 10px 0 0 0;
}

.fileOpen {
    float: left;
}

.moduleDetail {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: rgb(33, 33, 33, 0.7);
    color: white;
    justify-content: center;
    align-items: center;
    opacity: 0;
    z-index: 9;
}

.moduleDetail.learnDetail {
    z-index: 12;
}

.moduleContainer {
    display: grid;
    grid-template-columns: 1fr 0.38fr;
    width: calc(61vw - 40px);
    background: #2A2A2B;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 12px 20px 0px rgba(0, 0, 0, 0.3); 
    gap: 20px;
    max-height: 80vh;
}

.file {
    max-height: 80vh;
}

.fileForm {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 16/9;
}

.moduleContainer img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.closeModal {
    position: absolute;
    z-index: 11;
    left: 78.5vw;
    /* left: calc(80vw - 20px); */
}

.groupHeading {
    opacity: 0.5;
    transition: opacity 0.3s ease;
    display: grid;
    gap: 5px;
    grid-template-columns: 24px 220px 24px;
    margin-left: 0px;
    align-items: center;

    height: 30px;
}

.groupHeading .iconLg {
    transform: rotate(0deg);
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: all 0.3s ease;
    transform-origin: center;
}

.groupHeading .iconLg.off:hover, .groupHeading .iconLg.off {
    opacity: 0;
}

.pageBtnContainer.open .groupHeading .iconLg {
    transform: rotate(90deg);
}


.groupHeading .iconLg:hover {
    opacity: 1;
}

.groupHeading .iconLg path {
    fill: white;
}


.groupHeading:hover, .active .groupHeading {
    opacity: 1;
}

.groupHeading .icon {
    opacity: 0;
    transition: opacity 0.2s ease;
}


.on .groupHeading .icon {
    opacity: 1;
}

.groupHeading .pageBtn {
    align-items: center;
    display: flex;
}


.groupFiles {
    opacity: 0;
    height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}

.open .groupFiles {
    opacity: 1;
    height: auto;

}


.groupFilesIn {
    position: relative;
    height: 30px;
    display: flex;
    align-items: center;
    gap: 10px;

    /* top: 10px; */
    /* left: 10px; */
}


.moduleLeftNav .pageBtnContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.pageHeading {
    display: grid;
    grid-template-columns: 60px 1fr;
    width: 100%;
    height: 30px;
    align-items: center;
}

#galleryPage .pageHeading, .pageHeading.noPageCount {
    grid-template-columns: 1fr;
}

.navHeading {
    /* font-weight: 600; */
}

.moduleLeftNav .pageBtn.mediaFile {
    margin: 0;
}

.moduleLeftNav .pageBtn.mediaFile {
    opacity: 1;
}

.settingsRow .thumbnail {
    max-width: 200px;
}

.fileVideo video {
    width: 100%;
    height: 100%;
    aspect-ratio: 1.78/1;
    object-fit: contain;
}

.moduleSettings .deleteRow {

}

.iconLg .themeable {
    stroke: #666;
}

.categoryRows {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.categoryRow h2{
    margin: 0 0 20px 0;

}

.modalContainer .settingsRow {
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0;
    float: none;
}

.modalContainer .settingsRow input{
    max-width: 80%;
    font-size: 16px;
    min-height: 30px;
    padding: 5px 10px;
    color: white;
}

.outline .fontBtn{
    min-height: 20px;
    width: 80px;
    outline: 0.5px solid var(--white-20);
}

.filePlaceholder {
    aspect-ratio: 16/9;
    border: 1px solid var(--white-20);
    background: rgba(255,255,255,0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 96%;
}

.editableField {
    display: flex;
    gap: 20px;
    /* justify-content: space-between; */
    position: relative;

}

.emptyPdfThumbnail {
    aspect-ratio: 1.78/1;
    border: 1px solid var(--white-20);
    background: rgba(255,255,255,0.1);
    border-radius: 6px;
    max-width: 300px;
}

/* Mapbox */

#mapBox .leftNav {
    background: rgba(33, 33, 33, 1);
    /* backdrop-filter: blur(2px); */
}

#mapBox .previewBtn {
    right: 100px;
}

.mapOverlays {
    width: 100%;
}

#mapBox #manifestColumn {
    background: rgba(33, 33, 33, 0.8);
    backdrop-filter: blur(2px);
}

#mapBox .settingsOn .mediaSettings.showSettings.on {
    top: 10px;
    padding-top: 30px;

}

#mapBox #saveRow {
    bottom: 40px;
}

.mapBox {
    z-index: 0;
}

.map {
    width: 60vw;
    aspect-ratio: 16/9;
    position: absolute;
    left: 20vw;
    top: 80px;

}

.mapboxgl-canvas-container {
    /* width: 100vw;
    height: 100vh; */
}

.mapPages {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.marker {
    width: 30px;
    height: auto;
}

.markerOverlay svg, .marker svg {
    width: 100%;
    height: 100%;
  }

  .markerOverlay {
    /* pointer-events:none; */
    display: none;
    opacity: 0;
    position: absolute;
    z-index: 3;
    transition: opacity 2s ease 0.5s, animation 0.3s ease;
  }

  .markerOverlay.markerOn {
    display: inline;
    opacity: 1;
  }


  .markerOverlay svg path{
    animation-play-state: paused;
  }
  
  .markerOverlay.markerOn svg path{
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation-name: dash;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-play-state: running;
}

.markerOverlayGroup svg {
    width: 100%;
    height: 100%;
  }

  .markerOverlayGroup {
    /* pointer-events:none; */
    display: none;
    opacity: 0;
    position: absolute;
    z-index: 2;
  }

  .markerOverlayGroup g[class] {
      opacity: 0;
  }

  .markerOverlayGroup.svgReady {
    display: inline;
    opacity: 1;
  }

  .markerOverlayGroup g[class].markerOn {
    opacity: 1;
  }

  .markerOverlayGroup svg g[class] path{
    animation-play-state: paused;
  }
  
  .markerOverlayGroup svg g[class].markerOn path {
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation-name: dash;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-play-state: running;
}

.mapPages {
 display: flex;
 flex-direction: column;
 gap: 20px;
}


#version {
    position: fixed;
    bottom: 2vw;
    left: 2vw;
    z-index: 3;
    font-size: 12px;
    opacity: 0.3;
    line-height: 100%;
}

.noSubEvents > * {
    pointer-events: none;
}

.helpHover {
    position: absolute;
    display: flex;
    justify-content: center;
    opacity: 0;
}

.helpHoverIn {
    width: auto;
    max-width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.helpHoverText {
    color: white;
    background: #6a6a6a;
    border-radius: 8px;
    box-shadow: 0 12px 20px 0px rgba(0, 0, 0, 0.3); 
    /* max-width: 140px; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8px 8px;
    z-index: 1;
    position: relative;
}

.helpHoverCaret {
    position: relative;
    /* top: 24px; */
    width: 0px;

    border-top: 9px solid #6a6a6a;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    z-index: 2;
    top: -1px;
    transform: rotate(0);
 
  }

  .helpHoverCaret.reverse {
    top: -40px;
    transform: rotate(180deg);
  }


  .textSheild {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 4;
    display: block;
    float: left;
  }
  .textSheild:hover {
    cursor: crosshair;
  }

  .tabRow {
    position: absolute;
    left: calc(4% + 210px);
    width: calc(92% - 460px);
    padding: 0 2% 0 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(96% - 140px);
    z-index: 4;
    height: 40px;
    top: 10%;
   
  }

  .tabBtns {
    display: flex;
    /* background: var(--white-20); */
    flex-direction: column;
    /* border: 1px dotted var(--white-20); */
    position: relative;
  }

  .tabBtnIn {
    display: flex;
    gap: 20px;
  }

  .tabBtn {
    border: 1px solid var(--white-20);
    padding: 6px 12px;
    border-radius: 20px;
    transition: background 0.3s ease;
  }

  .tabBtn.on {
    background: var(--light-blue2);
    color: white;
  }

  .iconTextRow {
    display: flex;
    align-items: center;
  }

  .version {
    padding: 15px 0 15px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
 
  }


  .versionUser {
    color: var(--mid-grey)
  }

  .verionRow {
    /* min-height: 100px; */
  }

  .error {
    /* background:  */
    
  }


.learnContainer {
    position: fixed;
    top: 0px;
    width: 100vw;
    height: calc(100vh - 0px);
    background: #2A2A2B;
    z-index: 11;
}

.learnContainer .learnVideo {
    display: flex;
    flex-direction: column;
    top: 0px;
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.learnContainer video {
    width: 82%;
    height: auto;
}

.learnContainer .backBtn {
    position: fixed;
    top: 40px;
    left: 40px;
}

.productContact {
    max-width: 600px;
    text-align: center;
}

.productContact h2 {
    font-size: 30px;
    font-weight: 400;
}

.productContact p {
    font-size: 26px;
    font-weight: 400;
    margin-top: 30px;
}
.productContact a {
    font-weight: 700;
    /* border-bottom: 3px solid white; */
}


  @media only screen and (orientation: landscape) and (max-height: 1000px) {

    .tabRow {

        top: 0%;
       
      }

  }


